@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

.i::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 20%;
  background: white;
/*   background: #3B82F6; */
  /* Centering */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.i:hover:before {
  animation: anim-in 0.7s forwards ease-out;
}

.event-side-tab-gradient-one {
  background: linear-gradient(-45deg, #120949, #3099E9, #7E23B2, #C8519B, #120949);
	background-size: 300% 300%;
	animation: gradient 15s ease infinite ;
}

.event-side-tab-gradient-two {
  background: linear-gradient(-45deg, #120949, #7E23B2, #2F0E3B, #120949);
	background-size: 300% 300%;
	animation: gradient 22s ease infinite ;
}

.event-side-tab-gradient-three {
  background: linear-gradient(-45deg, #120949, #7E23B2, #33D7F0, #1F36A7, #33D7F0);
	background-size: 300% 300%;
	animation: gradient 12s ease infinite ;
}

p {
  font-family: 'Montserrat';
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@font-face {
  font-family: 'VCR_OSD_MONO';
  src: url(./VCR_OSD_MONO_1.001.ttf);
  font-weight: normal;
  font-style: normal;
}