.star-wars-section {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .star-wars-button-container {
    position: relative;
    width: 100%;
    min-height: 30vh;
    z-index: 1;
  }

.fade-star-wars {
    position: relative;
    width: 100%;
    min-height: 30vh;
    top: -25px;
    background-image: linear-gradient(0deg, transparent, #000 75%);
    z-index: 1;
  }

  .star-wars-form-bg {
    background-color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
  
  .star-wars {
    display: flex;
    justify-content: center;
    position: relative;
    height: 400px;
    color: #feda4a !important;
    font-family: 'FranklinGothic';
    font-size: 500%;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 120%;
    perspective: 400px;
    text-align: justify;
  }

  .star-wars p {
    font-family: 'FranklinGothic' !important;
  }

  .star-wars-planet-container
  {
    border-width:2px;
    border-color: #fff;
    padding: 15px;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
  }

  .star-wars-planet {
    transition: transform .2s; /* Animation */
  }

  .star-wars-planet:hover {
    transform: scale(1.3);
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .crawl {
    position: relative;
    top: 99999px;
    transform-origin: 50% 100%;
    animation: crawl 90s linear infinite;
  }

  .crawl > p {
    color: #feda4a !important;
    font-size: 70%;
  }
  @media (max-width: 992px) {
    .crawl > p {
      color: #feda4a !important;
      font-size: 30%;
      line-height: 110%;
    }

    .star-wars-form-bg {
      background-size: contain;
      background-color: rgb(0, 0, 0);
      background-repeat: no-repeat;
    }
  }
  
  .crawl > .title {
    font-size: 150%;
    text-align: center;
  }
  
  .crawl > .title h1 {
    font-family: 'Univers-LT';
    margin: 0 0 20px;
    font-size: 120%;
    text-transform: uppercase;
  }
  
  @keyframes crawl {
    0% {
      top: 300px;
      transform: rotateX(20deg)  translateZ(0);
    }
    100% { 
      top: -6000px;
      transform: rotateX(25deg) translateZ(-2500px);
    }
  }

  .side-tab-gradient-one {
    background: linear-gradient(-45deg, #000, #fff, #000, #000, #000, #000, #fff, #000);
      background-size: 300% 300%;
      animation: gradient 15s ease infinite ;
  }
  
  .side-tab-gradient-two {
    background: linear-gradient(-45deg, #fff, #fff, #fff, #fff, #fff, #fff, #fff, #fff);
      background-size: 300% 300%;
      animation: gradient 22s ease infinite ;
  }
  
  .side-tab-gradient-three {
    background: linear-gradient(-45deg, #000, #fff, #000, #000, #000, #000, #fff, #000);
      background-size: 300% 300%;
      animation: gradient 12s ease infinite ;
  }

.tab-container {
  height: fit-content;
}

.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.react-tabs__tab {
  background: rgba(0,0,0,.15);
    border: 2px solid #faf9f8;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.05);
    color: #faf9f8;
    display: block;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 700;
    flex: 1;
    letter-spacing: 1.25px;
    margin: 2px;
    min-width: 20%;
    padding: 10px 1px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

.react-tabs__tab.react-tabs__tab--selected {
  background: #faf9f8;
  color: #2f3037;
}

.table-row {
  width: 100%;
  background-color: #ffffff;
  padding: 15px;
  display: block;
  margin:5px;
  box-shadow: 0px 0px 12px 1px rgba(255, 255, 255, 0.3);
}

.table-row p {
  margin-bottom: 0px !important;
}

.table-agenda {
  width: -webkit-fill-available;
}